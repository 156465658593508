<template>
  <div class="h-100 position-relative">
    <div class="filter"></div>
    <fit-height-loader v-if="!ready" />
  </div>
</template>
<script>
import EXTRA_FILTERS from "@/graphql/ExtraFilters.gql";
import FitHeightLoader from "@/components/FitHeightLoader";
export default {
  components: {
    FitHeightLoader,
  },
  data() {
    return {
      ready: true,
    };
  },
  apollo: {
    extraFilters: {
      query: EXTRA_FILTERS,
    },
  },
  methods: {
    resetFilters() {
      _.each(this.extraFilters, (filter) => {
        filter.value = false;
        filter.values = [];
      });
    },
  },
};
</script>
